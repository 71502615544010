import React from 'react';
import ImgCrop from 'antd-img-crop';
import { Notification, Upload } from 'zui/Antd';
import { useSelector, useDispatch } from 'react-redux';
import { lockerPeopleActions } from 'reduxStore/lockerPeople/slice';
import superFetch from 'library/helpers/superFetch';
import { deleteFiles, uploadFiles } from 'library/firebase/fb.auth.utils';

const UploadImageFirebase = (props) => {
	const { entityID, entityName, setUploadLoading, uploadType, mapData, setUploadSuccessful, oldData, uploadRef } =
		props;
	const dispatch = useDispatch();
	const { fBPersonID } = useSelector((state) => state.Auth.selectedProfile);
	const {
		remoteConfig: { entityInfo }
	} = useSelector((state) => state.FbRemoteConfig);
	const {
		editPeopleModal: { data }
	} = useSelector((state) => state.Modals);

	const generateFilePath = async (file, userID) => {
		return (
			`${entityInfo[entityName].entityDir}/` +
			userID +
			Date.now() +
			(file.type === 'image/png' ? '.png' : '.jpeg')
		);
	};

	const cleanUpOldData = async (data, type) => {
		// Delete indoor maps reference in Zezamii DB
		if (data.mapID && data.url && type === 'indoorMap') {
			await superFetch.delete(`/maps/${data.mapID}`).then((res) => {
				if (res && res.statusCode === 200) {
					Notification('success', 'Old map and Device file deleted successfully');
				}
			});
			await deleteFiles(data.url);
		} else if (data.photoUrl && type === 'profilePicture') {
			if (!data.photoUrl.includes('/People/dummy')) {
				await deleteFiles(data.photoUrl);
			}
		}
	};

	const checkBeforeUpload = async (file) => {
		if (fBPersonID) {
			if (setUploadLoading) setUploadLoading(true);
			const correctFileType =
				file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp';
			const correctFileSize = file.size / 1024 / 1024 < 10;

			if (!correctFileType) {
				Notification('error', 'You can only upload JPEG/PNG/WEBP file!');
				return;
			}
			if (!correctFileSize) {
				Notification('error', 'Image must smaller than 10MB!');
				return;
			}
			if (!entityID) {
				Notification('error', 'Please provide correct EntityID');
				return;
			}
			if ((entityName === 'Floor' || entityName === 'Site') && !mapData) {
				Notification('error', 'Please provide correct Map Information');
				return;
			} else {
				//Upload file to firebase
				const filePath = await generateFilePath(file, fBPersonID);
				await uploadFiles(filePath, file);

				// Update entity with new image filepath
				if (uploadType === 'profilePicture' && (entityName === 'People' || entityName === 'Team') && filePath) {
					try {
						const body = { photoUrl: filePath };
						await superFetch.patch(`/${entityInfo[entityName].apiSlug}/${entityID}`, body).then((res) => {
							if (res && res.statusCode === 200) {
								switch (entityName) {
									case 'People':
										dispatch(
											lockerPeopleActions.updatePeoplePhoto({
												personID: entityID,
												...data,
												photoUrl: filePath
											})
										);
										break;
									case 'Team':
										break;
									default:
								}
								if (setUploadSuccessful) setUploadSuccessful(true);
								cleanUpOldData(oldData, uploadType);
							}
						});
					} catch (err) {
						Notification('error', 'Error while uploading image! Please try again.');
					}
				} else if (
					uploadType === 'indoorMap' &&
					(entityName === 'Floor' || entityName === 'Site') &&
					mapData &&
					filePath
				) {
					let mapID;
					// Create a indoor maps in Zezamii DB
					const mapBody = { ...mapData, url: filePath };
					await superFetch.post(`/maps/`, mapBody).then((res) => {
						if (res && res.statusCode === 201 && res.data) {
							mapID = res.data.ID;
						}
					});

					// Update entity with new image filepath
					if (mapID) {
						await superFetch
							.patch(`/${entityInfo[entityName].apiSlug}/${entityID}`, {
								mapID: mapID
							})
							.then((res) => {
								if (res && res.statusCode === 200) {
									switch (entityName) {
										case 'Floor':
											// dispatch(loadFloors());
											break;
										case 'Site':
											// dispatch(loadSites());
											break;
										default:
											Notification('error', 'Something went wrong! Please try again later.');
									}
									if (setUploadSuccessful) setUploadSuccessful(true);
									Notification('success', 'Floor Plan Updated Successfully.');
									cleanUpOldData(oldData, uploadType);
								}
							});
					} else {
						Notification('error', 'Error while uploading Floor Plan! Please try again.');
					}
				}
			}
			if (setUploadLoading) setUploadLoading(false);
			return correctFileType && correctFileSize && entityID;
		}
	};
	return (
		<ImgCrop
			{...props.cropperOptions}
			minZoom={0.1}
			zoom={true}
			onModalCancel={() => {
				if (setUploadLoading) setUploadLoading(false);
			}}
			cropperProps={{
				restrictPosition: false
			}}
		>
			<Upload
				ref={uploadRef}
				showUploadList={false}
				beforeUpload={(file) => checkBeforeUpload(file)}
				accept='.png,.jpeg,.jpg,.webp'
				{...props.uploadOptions}
				customRequest={() => {
					// Empty function to prevent default upload function
				}}
			>
				{props.children}
			</Upload>
		</ImgCrop>
	);
};
export default UploadImageFirebase;
