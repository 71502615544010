import { jwtDecode } from 'jwt-decode';

class AuthHelper {
	checkExpirity = (token) => {
		try {
			if (!token) return false;

			const profile = jwtDecode(token);
			const expiredAt = profile.expiredAt || profile.exp * 1000;
			if (expiredAt > new Date().getTime()) {
				return true;
			}
			return false;
		} catch (e) {
			return false;
		}
	};
}
export default AuthHelper;
