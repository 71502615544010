import { DownloadOutlined, HomeOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import LockOutlined from 'assets/images/svg/LockOutlined';
import { lazy } from 'react';

// LAZY LOAD OF Public PAGES IN THE PLATFORM WITH THEIR OWN NAME OF CHUNK
const Signup = lazy(() => import('publicContainer/Signup'));
const Signin = lazy(() => import('publicContainer/Signin'));
const ResetPassword = lazy(() => import('publicContainer/ResetPassword'));
const ForgotPassword = lazy(() => import('publicContainer/ForgotPassword'));

const FourZeroFour = lazy(() => import('publicContainer/404'));
const FourZeroOne = lazy(() => import('publicContainer/401'));
const FiveHundred = lazy(() => import('publicContainer/500'));

// ?  LAZY LOAD OF PRIVATE PAGES IN THE PLATFORM WITH THEIR OWN NAME OF CHUNK
const Home = lazy(() => import('mainDashboard/Home'));
const Site = lazy(() => import('mainDashboard/Site'));
const Sites = lazy(() => import('mainDashboard/Sites'));
// const Organisation = lazy(() => import('mainDashboard/Organisations'));
const Floors = lazy(() => import('mainDashboard/Floors'));
const Floor = lazy(() => import('mainDashboard/Floor'));
const People = lazy(() => import('mainDashboard/People'));
const Person = lazy(() => import('mainDashboard/Person'));
const Teams = lazy(() => import('mainDashboard/Teams'));
const Team = lazy(() => import('mainDashboard/Team'));
const Devices = lazy(() => import('mainDashboard/Devices'));
const AssetGroups = lazy(() => import('mainDashboard/AssetGroups'));
const Booking = lazy(() => import('mainDashboard/Bookings'));
const Access = lazy(() => import('mainDashboard/Access'));
const Inventory = lazy(() => import('mainDashboard/Inventory'));
const Settings = lazy(() => import('mainDashboard/Settings'));

//Locker Dashboard routes
const PeopleLocker = lazy(() => import('lockerDashboard/People'));
const HomeLocker = lazy(() => import('lockerDashboard/Home'));
const LockersLocker = lazy(() => import('lockerDashboard/Lockers'));
const SettingsLocker = lazy(() => import('lockerDashboard/Settings'));

export const ERROR_PAGES = [
	{
		path: '/404',
		component: FourZeroFour
	},
	{
		path: '/401',
		component: FourZeroOne
	},
	{
		path: '/500',
		component: FiveHundred
	}
];

export const PUBLIC_ROUTES = [
	{
		path: '/',
		exact: true,
		component: Signup
	},
	{
		path: '/signin',
		component: Signin
	},
	{
		path: '/signup',
		component: Signup
	},
	{
		path: '/forgotpassword',
		component: ForgotPassword
	},

	{
		path: '/resetpassword',
		component: ResetPassword
	}
	// {
	// 	path: '/createpassword',
	// 	component: CreateAccount
	// },
];

// ? Sidebar options for the main dashboard is now object containing the access and position of the sidebar
// ? The position is used to determine the position of the option in the sidebar menu
// ? 0 is for the Top Navigation Routes
// ? 1 is for the Bottom Navigation Routes
// ? The access is used to determine the access level of the user to view the option
// ? Setting sidebar to false would hide the option from the sidebar
export const MAIN_DASHBOARD_ROUTES = [
	{
		path: '', //? The path of the base dashboard route.
		component: Home,
		exact: true,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'Home',
		leftIcon: <HomeOutlined />,
		entityTypeID: 1,
		sidebar: {
			access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
			name: 'Dashboard',
			position: 0
		}
	},
	{
		path: 'sites',
		component: Sites,
		exact: true,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'Sites',
		leftIcon: <HomeOutlined />, //? Set the icon react component here
		entityTypeID: 2,
		sidebar: {
			access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
			name: 'Sites',
			position: 0
		}
	},
	{
		path: 'sites/site',
		component: Site,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'Site',
		sidebar: false
	},
	// {
	// 	path: 'organisations',
	// 	exact: true,
	// 	component: Organisation,
	// 	leftIcon: <HomeOutlined />,
	// 	access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
	// 	name: 'All Organisations',
	// 	sidebar: {
	// 		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
	// 		name: 'Organisations',
	// 		position: 0
	// 	}
	// },
	{
		path: 'floors',
		component: Floors,
		exact: true,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'Floors',
		leftIcon: <HomeOutlined />, //? Set the icon react component here
		entityTypeID: 3,
		optionSidebar: true,
		sidebar: {
			access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
			name: 'Floors',
			position: 0
		}
	},
	{
		path: 'floors/floor',
		component: Floor,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'Floor',
		sidebar: false
	},
	{
		path: 'people',
		component: People,
		exact: true,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'People',
		leftIcon: <HomeOutlined />, //? Set the icon react component here
		entityTypeID: 4,
		sidebar: {
			access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
			name: 'People',
			position: 0
		}
	},
	{
		path: 'people/person',
		component: Person,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'Person',
		sidebar: false
	},
	{
		path: 'teams',
		component: Teams,
		exact: true,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'Teams',
		leftIcon: <HomeOutlined />, //? Set the icon react component here
		entityTypeID: 5,
		sidebar: {
			access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
			name: 'Teams',
			position: 0
		}
	},
	{
		path: 'teams/team',
		component: Team,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'Team',
		sidebar: false
	},
	{
		path: 'devices',
		component: Devices,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'Assets & Devices',
		leftIcon: <HomeOutlined />, //? Set the icon react component here
		customIcon: true,
		entityTypeID: 6,
		sidebar: {
			access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
			name: 'Devices',
			position: 0
		}
	},
	{
		path: 'assetGroups',
		component: AssetGroups,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'Asset Groups',
		leftIcon: <HomeOutlined />, //? Set the icon react component here
		entityTypeID: 7,
		sidebar: {
			access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
			name: 'Asset Groups',
			position: 0
		}
	},
	{
		path: 'booking',
		component: Booking,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'Booking',
		leftIcon: <HomeOutlined />, //? Set the icon react component here
		entityTypeID: 8,
		sidebar: {
			access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
			name: 'Booking',
			position: 0
		}
	},
	{
		path: 'access',
		component: Access,

		access: [201, 202, 101, 103, 104],
		name: 'Access',
		leftIcon: <HomeOutlined />, //? Set the icon react component here
		entityTypeID: 9,
		sidebar: {
			access: [201, 202, 101, 103, 104],
			name: 'Access',
			position: 0
		}
	},
	{
		path: 'inventory',
		component: Inventory,
		exact: true,
		access: [201, 203],
		name: 'Inventory ',
		leftIcon: <HomeOutlined />, //? Set the icon react component here
		entityTypeID: 13,
		sidebar: {
			access: [201, 203],
			name: 'Inventory',
			position: 0
		}
	},

	{
		path: 'settings',
		component: Settings,
		access: [201, 202, 101, 102, 103],
		name: 'Settings',
		leftIcon: <HomeOutlined />, //? Set the icon react component here
		entityTypeID: 10,
		sidebar: {
			access: [201, 202, 101, 102, 103],
			name: 'Settings',
			position: 0
		}
	}
];

export const LOCKER_DASHBOARD_ROUTES = [
	{
		path: '', //? The path of the base dashboard route.
		component: HomeLocker,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'Home',
		leftIcon: <HomeOutlined />,
		entityTypeID: 1,
		sidebar: {
			access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
			name: 'Home',
			position: 0
		}
	},
	{
		path: 'lockers',
		component: LockersLocker,
		exact: true,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'Lockers',
		leftIcon: <LockOutlined />, //? Set the icon react component here
		entityTypeID: 2,
		sidebar: {
			access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
			name: 'Lockers',
			position: 0
		}
	},
	{
		path: 'people',
		component: PeopleLocker,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'People',
		leftIcon: <UserOutlined />, //? Set the icon react component here
		entityTypeID: 2,
		sidebar: {
			access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
			name: 'People',
			position: 0
		}
	},
	{
		path: 'settings',
		component: SettingsLocker,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'Settings',
		leftIcon: <SettingOutlined />, //? Set the icon react component here
		entityTypeID: 2,
		sidebar: {
			access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
			name: 'Settings',
			position: 0
		}
	},
	{
		path: 'download-app', // A unique key to identify the item
		name: 'Download App',
		access: [],
		leftIcon: <DownloadOutlined />,
		sidebar: {
			position: 1
		}
	}
];

const MainDashboard = lazy(() => import('mainDashboard/Layout'));
const LockerDashboard = lazy(() => import('lockerDashboard/Layout'));

/**
 * @description Defines the routes for the dashboards, List all the dashboards here eg. Lockers, MainDashboard, etc.
 * @type {Array}
 * @property {string} key - The key of the route.
 * @property {string} path - The path of the route.
 * @property {React.Component} component - The component to render for the route.
 */
export const DASHBOARD_ROUTES = [
	{
		key: 'default',
		path: 'dashboard',
		fullPath: '/dashboard',
		component: MainDashboard,
		index: true
	},
	{
		key: 'lockers',
		path: 'lockers',
		fullPath: '/lockers',
		component: LockerDashboard
	}
];
