const theme = {};

theme.palette = {
	primary: [
		'#3C6E71', // 0: Default
		'#366366', // 1: Darken 4%
		'#30585A', // 2: Darken 5%
		'#638B8D', // 3: Fade 20%
		'#507D7F', // 4: Lighten 3%
		'#8AA8AA', // 5: Fade 75%
		'#507D7F', // 6: Lighten 15%
		'#638B8D', // 7: Lighten 12%
		'#366366', // 8: Darken 2%
		'#2a6171', // 9: Algolia color
		'#2A4D4F', // 10: Lighten 8%
		'#366366', // 11: not used
		'#2A4D4F', // 12: darken 10%
		'#638B8D' // 13: Lighten 5%
	],
	secondary: [
		'#1D3557', // 0: primary layout
		'#D0DEE6', // 1: table header background
		'#434343', // 2: table header text
		'#4A5D79', // 3: page header
		'#172A46', // 4: not used
		'#172A46', // 5: DarkBlue Darken 5%
		'#f5f6f8', // 6: not used
		'#ecf1f5', // 7: not used
		'#457B9D', // 8: LighterBluish Lighten 2%
		'#6A95B1', // 9: LighterBluish Darken 3%
		'#112034', // 10: Sidebar submenu select
		'#6A95B136' // 11: Device Category sidebar select
	],
	color: [
		'#F8CA18', // 0: Orange
		'#F89713', // 1: Purple
		'#EC3D3A', // 2: Pink
		'#f64744', // 3: LimeGreen
		'#FF5B58', // 4: BlueShade
		'#EF4266', // 5: Yellow
		'#DE3B5C', // 6: Yellow Darken 5%
		'#A654D2', // 7:
		'#2AAECB', // 8:
		'#3DA05F', // 9:
		'#1890ff' // 10: Antd primary color
	],

	tagColor: [
		//preset design from antd
		'pink',
		'red',
		'yellow',
		'orange',
		'cyan',
		'green',
		'blue',
		'purple',
		'geekblue',
		'magenta',
		'volcano',
		'gold',
		'lime'
	],
	warning: [
		'#F8CA18' // 0: Warning
	],
	success: [
		'#00b16a' // 0: Success
	],
	error: [
		'#EC3D3A', // 0: Error
		'#AC0D0D', // 1: Darken 4%
		'#FF5B58', // 2: Lighten 8%
		'#FFe8e8', // 3: Deleted Row Table
		'#ffc3c3' // 4: Deleted Row Table Hover
	],
	grayscale: [
		'#bababa', // 0: GreyShade
		'#c1c1c1', // 1: GreyDark
		'#D8D8D8', // 2: Grey
		'#f1f1f1', // 3: GreyAlt
		'#F3F3F3', // 4: GreyLight
		'#fafafa', // 5: DarkWhite
		'#F9F9F9', // 6: DarkerWhite
		'#fcfcfc', // 7: #fff Darken 1%
		'#eeeeee', // 8:
		'#fbfbfb', // 9:
		'#f5f5f5', // 10:
		'#f7f8f9', // 11: today-highlight-bg
		'#f1f3f6', // 12: background Grey
		'#B2B2B2' // 13: Text Prop Grey
	],
	whitescale: [
		'#ffffff', // 0: White
		'#fafafa' // 1: DarkWhite
	],
	text: [
		'#111111', // 0: Heading
		'#232323', // 1: HeadingLight
		'#333333', // 2:
		'#353535', // 3:
		'#494949', // 4:
		'#5d5d5d', // 5:
		'#727272', // 6:
		'#888888', // 7:
		'#9f9f9f', // 8:
		'#b6b6b6' // 9:
	],
	border: [
		'#e9e9e9', // 0: Border
		'#d8d8d8', // 1: BorderDark
		'#ADC5EF', // 2: BorderLight
		'#5d5d5d', // 3:
		'#727272', // 4:
		'#888888', // 5:
		'#9f9f9f', // 6:
		'#b6b6b6', // 7:
		'#d3d3d3', // 8:
		'#E5ECF0' // 9:
	],

	calendar: [
		'#905', // 0:
		'#690', // 1:
		'#a67f59', // 2:
		'#07a', // 3:
		'#dd4a68', // 4:
		'#e90' // 5:
	],
	settings: [
		'#1D3557', // 0: Title Settings
		'#8A91A2', // 1: Description Settings
		'#0075FF', // 2: Button Background Settings
		'#000000', // 3: Selected Value
		'#001F97' // 4: Tag Role
	],
	tagCustomColor: [
		'#FFC3C3', // 0:  Red
		'#81B8EE', // 1:  Blue
		'#CCE9BB', // 2:  Green
		'#FFE58F' // 3: Yellow
	],
	tagBackgroundCustomColor: [
		'#e6f7ff', // 0:  Blue
		'#fffbe6', // 1:  Yellow
		'#f6ffed' // 2: Green
	]
};

theme.fonts = {
	primary: 'Montserrat, sans-serif',
	secondary: 'Lato, sans-serif',
	pre: 'Liberation Mono, monospace'
};

// This is deprecated - Try to avoid using it
const palette = (color, number) => theme.palette[color][number];

export { palette };

export default theme;
