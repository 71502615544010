import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: [],
	loading: false,
	error: {
		error: false,
		message: ''
	}
};

const devicesSlice = createSlice({
	name: 'Devices',
	initialState,
	reducers: {
		// Action for initial load
		loadDevices(state) {
			state.loading = true;
			state.data = [];
		},
		loadDevicesSuccess(state, action) {
			state.data = action.payload.data;
			state.loading = false;
		},
		loadDevicesError(state, action) {
			state.loading = false;
			state.error = { error: true, message: action.payload };
		},
		//Action For clean state
		cleanupState(state) {
			state.data = [];
			state.loading = false;
			state.error = { error: false, message: '' };
		}
	}
});

export const devicesActions = devicesSlice.actions;

export default devicesSlice.reducer;
