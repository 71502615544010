import { ConfigProvider } from 'antd';
import React from 'react';
import { Provider } from 'react-redux';
import Boot from 'reduxStore/boot';
import { store } from 'reduxStore/store';
import GlobalStyles from 'theme/globalStyles';
import theme from 'theme/theme.json';
import Navigation from './Navigation/index';

const App = () => (
	<Provider store={store}>
		<ConfigProvider theme={theme}>
			<GlobalStyles />
			<Navigation />
		</ConfigProvider>
	</Provider>
);
Boot()
	.then(() => App())
	.catch((error) => console.error(error));

export default App;
