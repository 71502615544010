import { store } from './store';
import { authActions } from './auth/slice';
import { remoteConfigActions } from 'reduxStore/fbRemoteConfig/slice';

const boot = () => {
	return new Promise(() => {
		store.dispatch(authActions.checkAuthorization()); //  trigger authentication check on page load
		store.dispatch(remoteConfigActions.loadRemoteConfig()); // trigger remote config load on page load
	});
};
export default boot;
