import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	orgSettings: [],
	orgSettingsLoading: false,
	personSettings: [],
	personSettingsLoading: false,
	selectedSettingGroupID: null,
	error: null
};

const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		loadOrganisationSettings(state) {
			state.orgSettingsLoading = true;
			state.error = null;
		},
		loadOrganisationSettingsSuccess(state, action) {
			state.orgSettings = action.payload;
			state.orgSettingsLoading = false;
		},
		loadOrganisationSettingsError(state, action) {
			state.orgSettingsLoading = false;
			state.error = action.payload;
		},
		loadPersonSettings(state) {
			state.personSettingsLoading = true;
			state.error = null;
		},
		loadPersonSettingsSuccess(state, action) {
			state.personSettings = action.payload;
			state.personSettingsLoading = false;
		},
		loadPersonSettingsError(state, action) {
			state.personSettingsLoading = false;
			state.error = action.payload;
		},
		updateSelectedSettingGroup(state, action) {
			state.selectedSettingGroupID = action.payload;
		}
	}
});

export const settingsActions = settingsSlice.actions;

export default settingsSlice.reducer;
