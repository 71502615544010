import { all } from 'redux-saga/effects';
import authSagas from 'reduxStore/auth/saga';
import FBRemoteConfigSagas from 'reduxStore/fbRemoteConfig/saga';
import settingsSagas from 'reduxStore/settings/saga';
import defaultSettingsSagas from 'reduxStore/defaultSettings/saga';
import workspacesSaga from 'reduxStore/workspaces/saga';
import lockerPeopleSagas from 'reduxStore/lockerPeople/saga';
import lockerPeopleMetricsSagas from 'reduxStore/lockerPeopleMetrics/saga';
import devicesSagas from 'reduxStore/devices/saga';
import sitesSagas from 'reduxStore/sites/saga';
import bookingSagas from 'reduxStore/booking/saga';

// import peopleSagas from '@iso/redux/people/saga';
// import organisationSagas from '@iso/redux/organisation/saga';
// import contactSagas from '@iso/redux/contacts/saga';
// import todosSagas from '@iso/redux/todos/saga';
// import devicesSagas from '@iso/redux/devices/saga';
// import teamsSagas from '@iso/redux/teams/saga';
// import typesSagas from '@iso/redux/types/saga';
// import floorsSagas from '@iso/redux/floors/saga';
// import deviceCategoriesSagas from '@iso/redux/deviceCategories/saga';
// import inventorySagas from '@iso/redux/inventory/saga';
// import assetGroupSagas from '@iso/redux/assetGroups/saga';
// import bookingSagas from '@iso/redux/booking/saga';
// import remoteConfigSagas from '@iso/redux/fbRemoteConfig/saga';
// import defaultSettingSagas from '@iso/redux/defaultSettings/saga';
// import settingSagas from '@iso/redux/settings/saga';

function* rootSaga(getState) {
	yield all([
		authSagas(),
		FBRemoteConfigSagas(),
		settingsSagas(),
		defaultSettingsSagas(),
		workspacesSaga(),
		lockerPeopleSagas(),
		lockerPeopleMetricsSagas(),
		devicesSagas(),
		sitesSagas(),
		bookingSagas()
	]);
}
export default rootSaga;
