import superFetch from 'library/helpers/superFetch';
import { put, takeEvery } from 'redux-saga/effects';
import { Notification } from 'zComponents/atoms';
import { Button } from 'zui/Antd';
import { defaultSettingsActions } from './slice';

function* loadDefaultOrgSettingsSaga() {
	try {
		const data = yield superFetch.get('/settings/organisation/defaultSettings').then((res) => res);

		if (!data.error) {
			yield put(defaultSettingsActions.loadDefaultOrganisationSettingsSuccess(data));
		} else {
			yield put(defaultSettingsActions.loadDefaultOrganisationSettingsError(data.error));
		}
	} catch (error) {
		Notification(
			'error',
			'There is an Error! We are mending the problem, try again soon!',
			<Button onClick={() => window.location.reload()}>Try again!</Button>
		);
		yield put(defaultSettingsActions.loadDefaultOrganisationSettingsError(error));
	}
}

function* loadDefaultPersonSettingsSaga() {
	try {
		const data = yield superFetch.get('/settings/people/defaultSettings').then((res) => res);

		if (!data.error) {
			yield put(defaultSettingsActions.loadDefaultPersonSettingsSuccess(data));
		} else {
			yield put(defaultSettingsActions.loadDefaultPersonSettingsError(data.error));
		}
	} catch (error) {
		Notification(
			'error',
			'There is an Error! We are mending the problem, try again soon!',
			<Button onClick={() => window.location.reload()}>Try again!</Button>
		);
		yield put(defaultSettingsActions.loadDefaultPersonSettingsError(error));
	}
}

function* loadGroupSettingsSaga() {
	try {
		const data = yield superFetch.get('/settings/group').then((res) => res);

		if (!data.error) {
			yield put(defaultSettingsActions.loadGroupSettingsSuccess(data));
		} else {
			yield put(defaultSettingsActions.loadGroupSettingsError(data.error));
		}
	} catch (error) {
		Notification(
			'error',
			'There is an Error! We are mending the problem, try again soon!',
			<Button onClick={() => window.location.reload()}>Try again!</Button>
		);
		yield put(defaultSettingsActions.loadGroupSettingsError(error));
	}
}

export default function* settingsSaga() {
	yield takeEvery(defaultSettingsActions.loadDefaultOrganisationSettings, loadDefaultOrgSettingsSaga);
	yield takeEvery(defaultSettingsActions.loadDefaultPersonSettings, loadDefaultPersonSettingsSaga);
	yield takeEvery(defaultSettingsActions.loadGroupSettings, loadGroupSettingsSaga);
}
