import { storage } from 'library/firebase/firebase';

export const getImageUrlFromStorage = async (urlInDatabase) => {
	return await storage
		.ref()
		.child(urlInDatabase)
		.getDownloadURL()
		.then((url) => {
			return url;
		})
		.catch((err) => {
			throw err;
		});
};
