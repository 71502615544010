import SuperFetch from 'library/helpers/superFetch';
import { call, put, takeEvery } from 'redux-saga/effects';
import { lockerPeopleMetricsActions } from './slice';

export function* loadLockerPeopleMetrics() {
	try {
		const peopleMetrics = yield call(() => SuperFetch.get('/lockers/people/metrics', {}));

		if (peopleMetrics.statusCode === 200) {
			yield put(lockerPeopleMetricsActions.loadLockerPeopleMetricsSuccess(peopleMetrics));
		} else {
			yield put(lockerPeopleMetricsActions.loadLockerPeopleMetricsError('Failed to load locker people metrics.'));
		}
	} catch (error) {
		yield put(lockerPeopleMetricsActions.loadLockerPeopleMetricsError('Failed to load locker people metrics.'));
	}
}

export default function* rootSaga() {
	yield takeEvery(lockerPeopleMetricsActions.loadLockerPeopleMetrics, loadLockerPeopleMetrics);
}
