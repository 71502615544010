import React from 'react';
import { TypographyTitleWrapper, TypographyParagraphWrapper, TypographyPropWrapper } from './styles';
import PropTypes from 'prop-types';

/**
 *	This is the component label, allowing you to add a unique style based on the dashboard UI
 *
 {
 * 	typeLabel = `primary1`,`secondary,`prop` these are the only options.
 * 	text= mandatory field
 * 	color = the color will be taken by the theme settings. \\ Example: palette(`primary`, 0) = '#3C6E71'
 * 	tonality = the tonality will be taken by the theme settings. \\ Example: palette(`primary`, 2) = '#30585A'
 * 	fontSize= size of the font
 * 	fontWeight= weight of the font
 * 
 * }
 * @returns
 */

const LabelWrapper = ({
	typeLabel = 'secondary', // Provide a default typeLabel
	color,
	tonality,
	text,
	fontSize,
	fontWeight,
	style,
	...props // Use the rest operator to capture all other props
}) => {
	const TypographyComponent =
		typeLabel === 'primary'
			? TypographyTitleWrapper
			: typeLabel === 'secondary'
			? TypographyParagraphWrapper
			: typeLabel === 'prop'
			? TypographyPropWrapper
			: TypographyParagraphWrapper; // Default component

	return (
		<TypographyComponent
			color={color}
			tonality={tonality}
			fontWeight={fontWeight}
			fontSize={fontSize}
			style={style}
			{...props} // Spread all other props
		>
			{text}
		</TypographyComponent>
	);
};

LabelWrapper.propTypes = {
	typeLabel: PropTypes.string,
	color: PropTypes.string,
	tonality: PropTypes.number,
	text: PropTypes.string.isRequired,
	fontSize: PropTypes.number,
	fontWeight: PropTypes.number
};

export default LabelWrapper;
