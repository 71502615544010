import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Loader } from 'zComponents/atoms';
import ErrorBoundary from './ErrorBoundary';
import { DASHBOARD_ROUTES, PUBLIC_ROUTES } from './routes';

const FourZeroFour = lazy(() => import('publicContainer/404'));

function RestrictedRoute() {
	const { idToken: isAuthenticated } = useSelector((state) => state.Auth);

	if (isAuthenticated) {
		return DASHBOARD_ROUTES.map(({ path, key, component: Component, index }) => (
			<Route index={index} path={`${path}/*`} key={key} element={<Component />} />
		));
	} else {
		return <Route replace path='/*' element={<Navigate to={'signin'} />} />;
	}
}

const Navigation = () => {
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<BrowserRouter>
					<Routes>
						{PUBLIC_ROUTES.map((route, index) => (
							<Route path={route.path} key={index} element={<route.component />} />
						))}
						{RestrictedRoute()}
						<Route path='*' element={<FourZeroFour />} />
					</Routes>
				</BrowserRouter>
			</Suspense>
		</ErrorBoundary>
	);
};
export default Navigation;
