import React from 'react';
import { matchPath, Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Breadcrumb, { BreadCrumbItem, BreadCrumbWrapper } from './BreadCrumb.styles';
import { MAIN_DASHBOARD_ROUTES } from 'navigation/routes';

const crumbs = [];
const HOME_ROUTE = '/dashboard/';
const BreadCrumb = () => {
	const location = useLocation();
	const getCrumbs = (currentPath) => {
		const pathUrl = currentPath.replace(HOME_ROUTE, '');
		const matchedPath = MAIN_DASHBOARD_ROUTES.find((path) => matchPath(pathUrl, path));

		const crumbAbleObject = { ...matchedPath, ...location };

		// Only show current 10 levels of breadcrumb
		if (crumbs.length > 10) {
			crumbs.shift();
		}

		const keyExist = crumbs.findIndex((crumb) => crumb.key === crumbAbleObject.key);

		// if crumb is not exist in crumbs array, then add it
		if (keyExist === -1) {
			if (crumbAbleObject?.path !== crumbs[crumbs.length - 1]?.path) {
				crumbs.push(crumbAbleObject);
			}
			return crumbs;
		} else {
			// if crumb is exist in crumbs array, then remove all the crumbs after it
			crumbs.length = keyExist + 1;
			return crumbs;
		}
	};
	function itemRender(route) {
		if (route.name === 'Home') {
			return (
				<Link to={route}>
					<i
						className='material-icons-outlined'
						style={{ fontSize: '13px', verticalAlign: 'middle', position: 'relative', top: '-1px' }}
					>
						home
					</i>
				</Link>
			);
		}
		return (
			<Link to={route}>
				<span style={{ verticalAlign: 'text-top', fontSize: '11px' }}>{route.name}</span>
			</Link>
		);
	}
	return (
		<BreadCrumbWrapper>
			{
				<Breadcrumb separator='/'>
					{getCrumbs(location.pathname).map((crumb) => (
						<BreadCrumbItem key={crumb.key}>{itemRender(crumb)}</BreadCrumbItem>
					))}
				</Breadcrumb>
			}
		</BreadCrumbWrapper>
	);
};

export default BreadCrumb;
