import React from 'react';
import LoaderComponent from './styles';

const loader = (props) => (
	<LoaderComponent {...props}>
		<svg className='isoContentLoader' viewBox='0 0 50 50'>
			<circle className='isoContentLoaderCircle' cx='25' cy='25' r='20' fill='none' strokeWidth='3.6' />
		</svg>
	</LoaderComponent>
);

export default loader;
