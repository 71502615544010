import { Grid, Row, Col } from 'antd';
import styled from 'styled-components';

export default styled(Grid)`
	// Add your styles here
`;

const StyledRow = styled(Row)`
	// Add your styles here
`;

const StyledCol = styled(Col)`
	// Add your styles here
`;

export { StyledRow, StyledCol };
