import SuperFetch from 'library/helpers/superFetch';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { workspacesActions } from './slice';

export function* getWorkspaces() {
	try {
		const { selectedProfile } = yield select((state) => state.Auth);
		const { fBPersonID, orgID } = selectedProfile;

		//get organizations by person
		const organisationsByPerson = yield call(() =>
			SuperFetch.get('/organisations/orgByPerson', {}, { fbPersonID: fBPersonID }, orgID)
		);

		if (organisationsByPerson.statusCode === 200) {
			yield put(workspacesActions.loadWorkspacesSuccess(organisationsByPerson.data));
		} else {
			throw new Error('Could not find workspaces by person');
		}
	} catch (error) {
		yield put(workspacesActions.loadWorkspacesError(error));
	}
}

export default function* rootSaga() {
	yield takeEvery([workspacesActions.loadWorkspaces], getWorkspaces);
}
