import { CheckCircleFilled } from '@ant-design/icons';
import { theme } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from 'reduxStore/modals/slice';
import { Button, Flex, Modal, Typography } from 'zui/Antd';

const { Title, Text } = Typography;

const SuccessModal = () => {
	//Redux
	const {
		successModal: { open, title, description }
	} = useSelector((state) => state.Modals);

	//Hooks
	const dispatch = useDispatch();
	const {
		token: { colorPrimary, colorTextDescription, fontSizeHeading3, marginSM, padding, paddingLG }
	} = theme.useToken();

	return (
		<Modal
			open={open}
			onCancel={() => dispatch(modalActions.closeSuccessModal())}
			maskClosable={false}
			closeIcon={false}
			centered
			destroyOnClose={true}
			footer={[
				<Flex align='center' justify='center' style={{ marginTop: marginSM }}>
					<Button
						onClick={() => dispatch(modalActions.closeSuccessModal())}
						type='primary'
						style={{ width: 141 }}
					>
						OK
					</Button>
				</Flex>
			]}
			styles={{
				content: {
					width: 720,
					padding: `${paddingLG}px ${padding}px'`
				}
			}}
		>
			<Flex vertical align='center' gap={24}>
				<CheckCircleFilled style={{ fontSize: 72, color: colorPrimary }} />
				<Flex vertical align='center' gap={4}>
					<Title style={{ margin: 0, fontSize: fontSizeHeading3, lineHeight: '32px' }}>{title}</Title>
					<Text style={{ fontSize: 14, lineHeight: '24px', color: colorTextDescription }}>{description}</Text>
				</Flex>
			</Flex>
		</Modal>
	);
};
export default SuccessModal;
