import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: [],
	loading: false,
	error: {
		error: false,
		message: ''
	}
};

const lockerPeopleSlice = createSlice({
	name: 'lockerPeopleMetrics',
	initialState,
	reducers: {
		// Action for initial load
		loadLockerPeopleMetrics(state) {
			state.loading = true;
			state.data = [];
		},
		loadLockerPeopleMetricsSuccess(state, action) {
			state.data = action.payload.data;
			state.loading = false;
		},
		loadLockerPeopleMetricsError(state, action) {
			state.loading = false;
			state.error = { error: true, message: action.payload };
		},
		//Action For clean error messages
		cleanupError(state) {
			state.error = { error: false, message: '' };
		}
	}
});

export const lockerPeopleMetricsActions = lockerPeopleSlice.actions;

export default lockerPeopleSlice.reducer;
