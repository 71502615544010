import { isServer } from './isServer';

export function getDefaultPath() {
	if (!isServer && window.location.pathname) {
		const routes = window.location.pathname.split('/');
		if (routes.length > 1) {
			const lastRoute = routes[routes.length - 1];
			return lastRoute;
		}
	}
	return [];
}
