import SuperFetch from 'library/helpers/superFetch';
import { call, put, takeEvery } from 'redux-saga/effects';
import { sitesActions } from './slice';

export function* getSites() {
	try {
		//get sites from the organization
		const sites = yield call(() => SuperFetch.get('/sites'));
		if (sites.statusCode === 200) {
			yield put(sitesActions.loadSitesSuccess(sites.data));
		} else {
			throw new Error('Could not find workspaces by person');
		}
	} catch (error) {
		yield put(sitesActions.loadSitesError(error));
	}
}

export default function* rootSaga() {
	yield takeEvery([sitesActions.loadSites], getSites);
}
