import { getCurrentUser } from 'library/firebase/fb.auth.utils';
import { db } from 'library/firebase/firebase';
import AuthHelper from 'library/helpers/authHelper';
import SuperFetch from 'library/helpers/superFetch';
import { clearToken, getToken } from 'library/helpers/utility';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { appActions } from 'reduxStore/app/slice';
import { workspacesActions } from 'reduxStore/workspaces/slice';
import { Notification } from 'zComponents/atoms';
import { authActions } from './slice';

export function* loginRequest() {
	yield takeEvery(authActions.login, function* ({ payload }) {
		const { token } = payload;
		if (token) {
			yield put(authActions.loginSuccess(token));
		} else {
			Notification('error', 'There is an Error! Please try again.');
			yield put(authActions.loginError());
		}
	});
}

export function* getUserProfile() {
	yield takeEvery([authActions.getUserProfile], function* () {
		try {
			const currentUser = yield call(getCurrentUser);

			const permissionSnapshot = yield db
				.collection('users')
				.doc(currentUser.uid)
				.collection('permission')
				.get()
				.then((querySnapshot) => {
					return querySnapshot;
				})
				.catch((err) => {
					throw err;
				});

			// Extract Permissions of the User
			let userPermissions = [];
			permissionSnapshot.forEach((org) => {
				userPermissions.push(org.data());
			});

			const od = parseInt(localStorage.getItem('od'), 10);
			const workSpace = userPermissions.find((org) => org.orgID === od) ?? userPermissions[0];

			// Get a person to check if is a new User
			const person = yield SuperFetch.get(`/people/${workSpace.personID}`, {}, {}, workSpace.orgID);

			if (userPermissions.length > 0 && person.statusCode === 200) {
				yield put(
					authActions.getUserProfileSuccess({
						selectedProfile: {
							...person.data,
							roles: workSpace.roles
						},
						userPermissions: userPermissions
					})
				);

				// Load the workspaces of the user
				yield put(workspacesActions.loadWorkspaces());
			} else {
			}
		} catch (error) {
			Notification('error', 'Error getting user profile. Please try again.');
			yield put(authActions.getUserProfileError(error));
		}
	});
}

export function* getUserProfileError() {
	yield takeEvery(authActions.getUserProfileError, function* () {
		yield put(authActions.logout());
	});
}

export function* loginSuccess() {
	yield takeEvery(authActions.loginSuccess, function* ({ payload: token }) {
		yield localStorage.setItem('id_token', token);
		yield put(authActions.getUserProfile());
	});
}

export function* loginError() {
	yield takeEvery(authActions.loginError, function* () {
		yield put(authActions.logout());
	});
}

export function* logout() {
	yield takeEvery(authActions.logout, function* () {
		yield clearToken();
	});
}

/**
 * Checks the authorization by listening to the CHECK_AUTHORIZATION action and validating the token.
 * If the token is valid and the user is logged in, it dispatches the loginSuccess action with the token.
 */
export function* checkAuthorization() {
	yield takeEvery(authActions.checkAuthorization, function* () {
		const token = getToken().get('idToken');
		const loggedIn = new AuthHelper().checkExpirity(token);
		if (token && loggedIn) {
			yield put(authActions.loginSuccess(token));
		}
	});
}

/**
 * This function handles the switching of user profiles.
 * It listens for the authActions.switchProfile action and takes in the orgID as payload.
 */
export function* switchPerson() {
	yield takeEvery(authActions.switchProfile, function* ({ payload: orgID }) {
		// Save the orgID in the local storage each time than change the profile
		localStorage.setItem('od', orgID);

		// Get the current user
		const currentUser = yield call(getCurrentUser);

		// Get the permission document for the specified orgID
		const personPermission = yield db
			.collection('users')
			.doc(currentUser.uid)
			.collection('permission')
			.doc(orgID.toString())
			.get()
			.catch((err) => {
				throw err;
			});

		// Get the person profile from the permission document
		const personProfile = personPermission.data();

		// Get the person data from the API using the personID and orgID
		const person = yield SuperFetch.get(`/people/${personProfile.personID}`, {}, {}, orgID);

		// Check if the person data was retrieved successfully
		if (!person.error) {
			// Dispatch the switchProfileSuccess action with the person data and roles
			yield put(
				authActions.switchProfileSuccess({
					...person.data,
					roles: personProfile.roles
				})
			);
			yield put(appActions.changeCurrent(['']));
		} else {
			// Display an error notification and dispatch the switchProfileError action
			Notification('error', 'There is an error switching your profile. We are working on it!.');
			yield put(
				authActions.switchProfileError('There is an error switching your profile. We are working on it!.')
			);
		}
	});
}

// This function handles the switching of the dashboard.
export function* switchDashboard() {
	// Listen for the switchDashboard action and take in the payload
	yield takeEvery(authActions.switchDashboard, function* ({ payload }) {
		// Perform the switchDashboard tasks here

		// put the selected dashboard in the local storage
		localStorage.setItem('d', payload);
		// Dispatch the switchDashboardSuccess action
		yield put(authActions.switchDashboardSuccess());
		// Dispatch the switchDashboardSuccess action
		yield put(appActions.clearMenu());
	});
}

export default function* rootSaga() {
	yield all([
		fork(checkAuthorization),
		fork(loginRequest),
		fork(loginSuccess),
		fork(loginError),
		fork(logout),
		fork(getUserProfile),
		fork(switchPerson),
		fork(switchDashboard)
	]);
}
