import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: [],
	loading: false,
	error: null
};

const workspacesSlice = createSlice({
	name: 'workspaces',
	initialState,
	reducers: {
		loadWorkspaces(state) {
			state.loading = true;
		},
		loadWorkspacesSuccess(state, action) {
			state.data = action.payload;
			state.loading = false;
		},
		loadWorkspacesError(state, action) {
			state.error = action.payload;
			state.loading = false;
		}
	}
});

export const workspacesActions = workspacesSlice.actions;

export default workspacesSlice.reducer;
