import { Layout } from 'antd';
import styled from 'styled-components';
const { Sider } = Layout;

export default styled(Sider)`
	// Add your styles here
	display: flex;
	flex-direction: column;
	padding: 0px 14px;

	&.ant-layout-sider {
		/* All the style to attach to the sider */
		border-right: 1px solid ${({ colorBorder }) => colorBorder};

		.ant-layout-sider-children {
			/* All the style to attach to the children of the sider */
			display: flex;
			flex-direction: column;
			gap: 15px;

			.sidebarMenuContainer {
				overflow-y: auto;
				height: 100%;
				justify-content: space-between;

				.ant-menu:nth-of-type(2) {
					margin-bottom: 15px;
				}

				/* Apply below style only if the menu is collapsed */
				.ant-menu-inline,
				.ant-menu-inline-collapsed {
					border-inline-end: 0px;
				}

				.ant-menu-item {
					margin: 0;
					width: 100%;
					margin-bottom: 15px;

					&:last-child {
						margin-bottom: 0;
					}
				}

				.ant-menu-inline-collapsed {
					.ant-menu-item {
						display: flex;
						flex-wrap: wrap;
						justify-content: center;
						height: 60px;
						align-content: center;
						flex-direction: column;
						padding: 0;
						align-items: center;
						gap: 5px;

						/* gap between the menu and sidebar */
						margin-inline: 10px;
						margin-block: 5px;
						width: calc(100% - 20px);

						.ant-menu-title-content {
							opacity: 1;
							margin: 0;
							line-height: normal;
						}
					}
				}
			}
		}
	}
`;
