import { createSlice } from '@reduxjs/toolkit';
const initialState = {
	idToken: null,
	loginLoading: false,
	getProfileLoading: false,
	error: null,
	dashboard: {
		selected: localStorage.getItem('d') || 'default', // default, access, locker, profile,
		switching: false
	},
	switchingProfile: false,
	selectedProfile: {},
	userPermissions: [],
	profileLoadingComplete: false
};

// Create Auth reducer slice
const authSlice = createSlice({
	name: 'auth',
	initialState: initialState,
	reducers: {
		login(state) {
			state.loginLoading = true;
			state.error = null;
		},
		loginSuccess(state, { payload: token }) {
			state.loginLoading = false;
			state.idToken = token;
		},
		loginError(state, action) {
			state.loginLoading = false;
			state.error = action.payload;
		},
		getUserProfile(state, action) {
			state.getProfileLoading = true;
			state.error = null;
		},
		getUserProfileSuccess(state, action) {
			state.getProfileLoading = false;
			state.selectedProfile = action.payload.selectedProfile;
			state.userPermissions = action.payload.userPermissions;
			state.profileLoadingComplete = true;
		},
		getUserProfileError(state, action) {
			state.getProfileLoading = false;
			state.error = action.payload;
		},
		logout() {
			return initialState;
		},
		checkAuthorization(state) {
			state.loginLoading = true;
			state.error = null;
		},
		switchProfile(state) {
			state.switchingProfile = true;
			state.error = null;
		},
		switchProfileSuccess(state, action) {
			state.switchingProfile = false;
			state.selectedProfile = action.payload;
		},
		switchProfileError(state, action) {
			state.switchingProfile = false;
			state.error = action.payload;
		},
		switchDashboard(state, action) {
			state.dashboard.selected = action.payload;
			state.dashboard.switching = true;
		},
		switchDashboardSuccess(state) {
			state.dashboard.switching = false;
		},
		switchDashboardError(state, action) {
			state.dashboard.switching = false;
			state.error = action.payload;
		}
	}
});
// Export Auth reducer
export default authSlice.reducer;

// export Auth reducer actions
export const authActions = authSlice.actions;
