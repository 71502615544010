import { settingFieldType } from 'config/settingFieldType';

export const mergedSettings = (defaultSettings, settings) => {
	return defaultSettings.map((defaultSetting) => {
		const matchingItem = settings.find((setting) => setting.defaultSettingID === defaultSetting.defaultSettingID);

		let parsedValueOptions = [];
		let parsedValue = null;

		if (defaultSetting.fieldTypeID === settingFieldType.SINGLESELECT) {
			try {
				parsedValueOptions = JSON.parse(defaultSetting.valueOptions);
			} catch (error) {
				console.log('error parsing SINGLESELECT valueOptions: ' + error);
			}
		} else if (defaultSetting.fieldTypeID === settingFieldType.MULTISELECT) {
			try {
				parsedValueOptions = JSON.parse(defaultSetting.valueOptions);
				parsedValue = JSON.parse(
					matchingItem && matchingItem.value.length > 0 ? matchingItem.value : defaultSetting.defaultValue
				);
			} catch (error) {
				console.log('error parsing MULTISELECT valueOptions or value: ' + error);
			}
		} else if (defaultSetting.fieldTypeID === settingFieldType.BOOLEAN) {
			parsedValue = parseFloat(
				matchingItem && matchingItem.value.length > 0 ? matchingItem.value : defaultSetting.defaultValue
			);
		} else if (defaultSetting.fieldTypeID === settingFieldType.MULTIVALUE) {
			try {
				parsedValue = JSON.parse(
					matchingItem && matchingItem.value.length > 0 ? matchingItem.value : defaultSetting.defaultValue
				);
			} catch (error) {
				console.log('error parsing MULTIVALUE value: ' + error);
			}
		} else if (defaultSetting.fieldTypeID === settingFieldType.CREDENTIAL) {
			try {
				parsedValue = JSON.parse(
					matchingItem && matchingItem.value.length > 0 ? matchingItem.value : defaultSetting.defaultValue
				);
			} catch (error) {
				console.log('error parsing CREDENTIAL value: ' + error);
			}
		}

		const mergedItem = {
			...defaultSetting,
			valueOptions: parsedValueOptions.length > 0 ? parsedValueOptions : defaultSetting.valueOptions,
			value:
				parsedValue !== null
					? parsedValue
					: matchingItem && matchingItem.value.length > 0
					? matchingItem.value
					: defaultSetting.defaultValue
		};

		return mergedItem;
	});
};
