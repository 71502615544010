import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: [],
	isLoadingGetBookings: false,
	isLoadingCreateBooking: false,
	error: false,
	message: ''
};

const bookingSlice = createSlice({
	name: 'booking',
	initialState,
	reducers: {
		createBooking(state) {
			state.isLoadingCreateBooking = true;
			state.error = false;
			state.message = '';
		},
		createBookingSuccess(state) {
			state.isLoadingCreateBooking = false;
			state.error = false;
			state.message = 'Booking created successfully';
		},
		createBookingError(state) {
			state.isLoadingCreateBooking = false;
			state.error = true;
			state.message = 'Failed to create booking';
		}
	}
});

export const bookingActions = bookingSlice.actions;

export default bookingSlice.reducer;
