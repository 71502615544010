import { takeEvery, put } from 'redux-saga/effects';
import { remoteConfigActions } from './slice';
import { getRemoteConfig } from 'library/firebase/fb.remoteConfig.utils';
import { Notification } from 'zComponents/atoms';
import { Button } from 'zui/Antd';

// Remote config saga
function* loadRemoteConfigSaga() {
	try {
		const rConfig = yield getRemoteConfig().catch((error) => {
			throw new Error(error);
		});

		yield put(remoteConfigActions.loadRemoteConfigSuccess(rConfig));
	} catch (error) {
		yield put(remoteConfigActions.loadRemoteConfigError(error));
		Notification(
			'error',
			'Error loading data! Please refresh the page and try again.',
			<Button onClick={() => window.location.reload()}>Try again!</Button>
		);
	}
}

export default function* remoteConfigSaga() {
	yield takeEvery(remoteConfigActions.loadRemoteConfig, loadRemoteConfigSaga);
}
