import { createSlice } from '@reduxjs/toolkit';
import { getDefaultPath } from 'library/helpers/url_sync';
import { isServer } from 'library/helpers/isServer';
import { getView } from 'library/helpers/utility';

const preKeys = getDefaultPath();
const initialState = {
	collapsed: !isServer && window.innerWidth > 1220 ? false : true,
	view: !isServer && getView(window.innerWidth),
	height: !isServer && window.innerHeight,
	openDrawer: false,
	openKeys: preKeys,
	current: preKeys
};

const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		collapseChange(state) {
			state.collapsed = !state.collapsed;
		},
		collapseOpenDrawer(state) {
			state.openDrawer = !state.openDrawer;
		},
		toggleAll(state, action) {
			const appView = getView(action.payload.width);
			const appCollapsed = appView !== 'DesktopView';
			if (state.view !== appView || action.payload.height !== state.height) {
				const height = action.payload.height ? action.payload.height : state.height;
				state.collapsed = appCollapsed;
				state.view = appView;
				state.height = height;
			}
		},
		changeOpenKeys(state, action) {
			state.openKeys = action.payload;
		},
		changeCurrent(state, action) {
			state.current = action.payload;
		},
		clearMenu(state) {
			state.openKeys = [];
			state.current = [];
		}
	}
});

export const appActions = appSlice.actions;

export default appSlice.reducer;
