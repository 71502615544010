import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: [],
	pagination: {
		total: 0,
		totalPages: 0,
		page: 1,
		size: 12
	},
	isLoadingInitialData: false,
	isLoadingMoreData: false,
	isLoadingFilteredData: false,
	loadingUpdateProfile: false,
	loadingDeleteProfile: false,
	loadingEnableProfile: false,
	loadingDisableProfile: false,
	isLoadingPeoplePhoto: false,
	loadingResetPassword: false,
	error: false,
	message: ''
};

const lockerPeopleSlice = createSlice({
	name: 'lockerPeople',
	initialState,
	reducers: {
		// Action for initial load
		loadInitialLockerPeople(state) {
			state.isLoadingInitialData = true;
			state.data = [];
			state.pagination.page = 1;
			state.error = false;
			state.message = '';
		},
		loadInitialLockerPeopleSuccess(state, action) {
			state.data = action.payload.data;
			state.pagination = {
				...action.payload.pagination,
				totalPages: Math.ceil(action.payload.pagination.total / action.payload.pagination.size)
			};
			state.isLoadingInitialData = false;
		},
		loadInitialLockerPeopleError(state, action) {
			state.isLoadingInitialData = false;
			state.error = true;
			state.message = 'Failed to load initial locker people.';
		},
		// Action for loading more data during infinite scroll
		loadMoreLockerPeople(state) {
			state.isLoadingMoreData = true;
			state.error = false;
			state.message = '';
		},
		loadMoreLockerPeopleSuccess(state, action) {
			state.data = [...state.data, ...action.payload.data];
			state.pagination = {
				...action.payload.pagination,
				totalPages: Math.ceil(action.payload.pagination.total / action.payload.pagination.size)
			};
			state.isLoadingMoreData = false;
		},
		loadMoreLockerPeopleError(state) {
			state.isLoadingMoreData = false;
			state.error = true;
			state.message = 'Failed to load more locker people.';
		},
		// Action to trigger filtered data load
		loadFilteredLockerPeople(state) {
			state.isLoadingFilteredData = true;
			state.error = false;
			state.message = '';
		},
		loadFilteredLockerPeopleSuccess(state, action) {
			state.data = action.payload.data;
			state.pagination = {
				...action.payload.pagination,
				totalPages: Math.ceil(action.payload.pagination.total / action.payload.pagination.size)
			};
			state.isLoadingFilteredData = false;
		},
		loadFilteredLockerPeopleError(state) {
			state.isLoadingFilteredData = false;
			state.error = true;
			state.message = 'Failed to load filtered people.';
		},
		// Action to update people
		updatePeople(state) {
			state.loadingUpdateProfile = true;
			state.error = false;
			state.message = '';
		},
		updatePeopleSuccess(state, action) {
			state.data = state.data.map((person) =>
				person.personID === action.payload.personID ? { ...person, ...action.payload.data } : person
			);
			state.loadingUpdateProfile = false;
			state.error = false;
			state.message = 'Profile Updated Successfully!';
		},
		updatePeopleError(state) {
			state.loadingUpdateProfile = false;
			state.error = true;
			state.message = 'Failed to save changes. Please try again.';
		},
		// Actions for delete
		deletePeople(state) {
			state.loadingDeleteProfile = true;
			state.error = false;
			state.message = '';
		},
		deletePeopleSuccess(state, action) {
			state.data = state.data.filter((person) => person.personID !== action.payload);
			state.pagination.total -= 1;
			state.loadingDeleteProfile = false;
			state.error = false;
			state.message = 'Profile has been permanently deleted.';
		},
		deletePeopleError(state) {
			state.loadingDeleteProfile = false;
			state.error = true;
			state.message = 'Failed to delete. Please try again.';
		},
		// Actions for enable
		enablePeople(state) {
			state.loadingEnableProfile = true;
			state.error = false;
			state.message = '';
		},
		enablePeopleSuccess(state, action) {
			state.data = state.data.map((person) =>
				person.personID === action.payload ? { ...person, isActive: true } : person
			);
			state.loadingEnableProfile = false;
			state.error = false;
			state.message = 'Profile has been enabled.';
		},
		enablePeopleError(state) {
			state.loadingEnableProfile = false;
			state.error = true;
			state.message = 'Failed to enable. Please try again.';
		},
		// Actions for disable
		disablePeople(state) {
			state.loadingDisableProfile = true;
			state.error = false;
			state.message = '';
		},
		disablePeopleSuccess(state, action) {
			state.data = state.data.map((person) =>
				person.personID === action.payload ? { ...person, isActive: false } : person
			);
			state.loadingDisableProfile = false;
			state.error = false;
			state.message = 'Profile has been disabled.';
		},
		disablePeopleError(state) {
			state.loadingDisableProfile = false;
			state.error = true;
			state.message = 'Failed to disable. Please try again.';
		},
		// Actions for reset password
		resetPeoplePassword(state) {
			state.loadingResetPassword = true;
			state.error = false;
			state.message = '';
		},
		resetPeoplePasswordSuccess(state) {
			state.loadingResetPassword = false;
			state.error = false;
			state.message = 'Password reset sent successfully.';
		},
		resetPeoplePasswordError(state) {
			state.loadingResetPassword = false;
			state.error = true;
			state.message = 'Failed to send password reset. Please try again.';
		},
		// Actions for update profile photo
		updatePeoplePhoto(state) {
			state.isLoadingPeoplePhoto = true;
			state.error = false;
			state.message = '';
		},
		updatePeoplePhotoSuccess(state, action) {
			state.data = state.data.map((person) =>
				person.personID === action.payload.personID ? { ...person, photoUrl: action.payload.photoUrl } : person
			);
			state.isLoadingPeoplePhoto = false;
			state.error = false;
			state.message = 'Profile Photo Updated Successfully!';
		},
		updatePeoplePhotoError(state) {
			state.isLoadingPeoplePhoto = false;
			state.error = true;
			state.message = 'Failed to Update Photo. Please try again.';
		},
		// Action for cleaning error messages
		cleanupError(state) {
			state.error = false;
			state.message = '';
		}
	}
});

export const lockerPeopleActions = lockerPeopleSlice.actions;

export default lockerPeopleSlice.reducer;
