import firebase, { auth, storage } from './firebase';

export const getCurrentUser = () => {
	return new Promise((resolve, reject) => {
		const unsubscribe = auth.onAuthStateChanged((userAuth) => {
			unsubscribe();
			resolve(userAuth);
		}, reject);
	});
};
export const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account' });

export const microsoftProvider = new firebase.auth.OAuthProvider('microsoft.com');

export const signInWithGoogle = () => auth.signInWithPopup(googleProvider);

export const signInWithEmail = async (email, password) => await auth.signInWithEmailAndPassword(email, password);
export const signInWithMicrosoft = () => auth.signInWithPopup(microsoftProvider);
export const linkUserWithMicrosoft = () => auth.currentUser.linkWithPopup(microsoftProvider);

export const signUpWithEmailAndPassword = async (email, password) =>
	await auth.createUserWithEmailAndPassword(email, password);

export const resetPassword = (email) => auth.sendPasswordResetEmail(email);

export const signOut = () => auth.signOut();

export const uploadFiles = async (filePath, file) => {
	let imageRef = storage.ref().child(filePath);
	await imageRef.put(file).catch((e) => e);
};
export const deleteFiles = async (filePath) => {
	let imageRef = storage.ref().child(filePath);
	await imageRef
		.delete()
		.then((success) => success)
		.catch((e) => e);
};

export default auth;
