import { takeEvery, put, select } from 'redux-saga/effects';
import superFetch from 'library/helpers/superFetch';
import { Notification } from 'zComponents/atoms';
import { Button } from 'zui/Antd';
import { settingsActions } from './slice';
import { mergedSettings } from 'library/helpers/settingsHelper';

// Organisation settings saga
function* loadOrganisationSettingsSaga() {
	try {
		const data = yield superFetch.get('/settings/organisation/defaultSettings').then((res) => res);

		if (!data.error) {
			yield put(settingsActions.loadOrganisationSettingsSuccess(data));
		} else {
			yield put(settingsActions.loadOrganisationSettingsError(data.error));
		}
	} catch (error) {
		Notification(
			'error',
			'There is an Error! We are mending the problem, try again soon!',
			<Button onClick={() => window.location.reload()}>Try again!</Button>
		);
		yield put(settingsActions.loadOrganisationSettingsError('Error'));
	}
}

// Person settings saga
function* loadPersonSettingsSaga() {
	const { personDefaultSettings } = yield select((state) => state.settings);
	const { personID } = yield select((state) => state.profile);

	try {
		const personSettings = yield superFetch.get('/settings/people', {}, { personID }).then((res) => res);

		const data = mergedSettings(personDefaultSettings, personSettings);

		if (!data.error) {
			yield put(settingsActions.loadPersonSettingsSuccess(data));
		} else {
			yield put(settingsActions.loadPersonSettingsError(data.error));
		}
	} catch (error) {
		Notification(
			'error',
			'There is an Error! We are mending the problem, try again soon!',
			<Button onClick={() => window.location.reload()}>Try again!</Button>
		);
		yield put(settingsActions.loadPersonSettingsError('Error'));
	}
}

export default function* settingsSaga() {
	yield takeEvery(settingsActions.loadOrganisationSettings, loadOrganisationSettingsSaga);
	yield takeEvery(settingsActions.loadPersonSettings, loadPersonSettingsSaga);
}
