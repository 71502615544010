import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	remoteConfig: [],
	loading: true,
	error: null
};

const remoteConfigSlice = createSlice({
	name: 'remoteConfig',
	initialState,
	reducers: {
		loadRemoteConfig(state) {
			state.loading = true;
			state.error = null;
		},
		loadRemoteConfigSuccess(state, action) {
			state.remoteConfig = action.payload;
			state.loading = false;
		},
		loadRemoteConfigError(state, action) {
			state.loading = false;
			state.error = action.payload;
		}
	}
});

export const remoteConfigActions = remoteConfigSlice.actions;

export default remoteConfigSlice.reducer;
