import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/auth';
import 'firebase/compat/performance';
import 'firebase/compat/remote-config';
import fbConfig from 'config/firebase.config';

export const firebaseApp = !firebase.apps.length ? firebase.initializeApp(fbConfig) : firebase.app();
export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const perf = firebase.performance();
export const remoteConfig = firebase.remoteConfig();
export default firebase;
