import SuperFetch from 'library/helpers/superFetch';
import { call, put, takeEvery } from 'redux-saga/effects';
import { devicesActions } from './slice';

export function* loadDevices() {
	try {
		const devices = yield call(() => SuperFetch.get('/devices', {}));

		if (devices.statusCode === 200) {
			yield put(devicesActions.loadDevicesSuccess(devices));
		} else {
			yield put(devicesActions.loadDevicesError('Failed to load available devices.'));
		}
	} catch (error) {
		yield put(devicesActions.loadDevicesError('Failed to load available devices.'));
	}
}

export default function* rootSaga() {
	yield takeEvery(devicesActions.loadDevices, loadDevices);
}
